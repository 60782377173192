<template>
  <div class="card p-3 shadow-sm">
    <form @submit.prevent="createVessel">
      <div class="row justify-content-between">
        <div class="col-auto">
          <h5 class="e-text-red">ADD VESSEL</h5>
        </div>
        <div class="col-auto">
          <button class="e-btn e-btn-outlined-red ml-2" type="button"  @click="cancelAddVessel">Cancel</button>
          <button class="e-btn e-btn-blue ml-2" type="submit"  >Save</button>
        </div>
      </div>
      <table class="w-100" id="vesselDetailsTbl">
        <tr>
          <th>Ship Name: </th>
          <td class="text-left" >
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.name">
          </td>
          <th>Abbreviation: </th>
          <td class="text-left" >
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.abbreviation">
          </td>
          <th >Status: </th>
          <td class="text-left">
            <select class="form-control form-control-sm"  v-model="newVessel.active">
              <option value="1">ACTIVE</option>
              <option value="0">INACTIVE</option>
            </select>
          </td>
        </tr>
        <tr>
          <th>Previous names: </th>
          <td class="text-left">
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.previous_names">
          </td>
          <th >IMO No: </th>
          <td class="text-left">
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.imo_no">
          </td>
          <th >Ship Type: </th>
          <td class="text-left">
            <select class="form-control form-control-sm"  v-model="newVessel.vessel_type_id">
              <option :value="vesselType.id" :key="vesselType.id" v-for="vesselType in vesselTypeKeys" class="text-uppercase">
                {{vesselType.name}}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <th>Call Sign: </th>
          <td class="text-left">
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.call_sign">
          </td>
          <th >MMSI: </th>
          <td class="text-left">
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.mmsi">
          </td>
          <th>Flag: </th>
          <td class="text-left">
            <select class="form-control form-control-sm"  v-model="newVessel.flag_id">
              <option :value="country.id" :key="country.id" class="text-uppercase"
                      v-for="country in countryKeys">{{country.name}}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <th>Class: </th>
          <td class="text-left">
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.class">
          </td>
          <th>Class No: </th>
          <td class="text-left">
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.class_no">
          </td>
          <th>Classification Characters, Notations: </th>
          <td class="text-left">
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.classification_characters">
          </td>
        </tr>
        <tr>
          <th>Installation Characters: </th>
          <td class="text-left">
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.installation_characters">
          </td>
          <th>Tonnage Gross(International): </th>
          <td class="text-left">
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.tonnage_gross_international">
          </td>
          <th>Tonnage Gross(Registered): </th>
          <td class="text-left">
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.tonnage_gross_registered">
          </td>
        </tr>
        <tr>
          <th>Deadweight: </th>
          <td class="text-left">
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.dead_weight">
          </td>
          <th>Cargo Capacity: </th>
          <td class="text-left">
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.cargo_capacity">
          </td>
          <th>Summer Freeboard: </th>
          <td class="text-left">
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.summer_freeboard">
          </td>
        </tr>
        <tr>
          <th>Summer Draft: </th>
          <td class="text-left">
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.summer_draft">
          </td>
          <th>LOA (m): </th>
          <td class="text-left">
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.loa">
          </td>
          <th>Moulded LxBxD: </th>
          <td class="text-left">
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.moulded_lbd">
          </td>
        </tr>
        <tr>
          <th>Registered LxBxD: </th>
          <td class="text-left">
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.registered_lbd">
          </td>
          <th>Shipbuilder: </th>
          <td class="text-left">
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.shipbuilder">
          </td>
          <th>Hull Number: </th>
          <td class="text-left">
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.hull_number">
          </td>
        </tr>
        <tr>
          <th>Sister ship of: </th>
          <td class="text-left">
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.sister_ship_of">
          </td>
          <th>Date of Contract: </th>
          <td class="text-left">
            <input type="date" class="form-control form-control-sm" :min="minimumDate" :max="currentDate"  v-model="newVessel.date_contract">
          </td>
          <th>Date of Keel Lay: </th>
          <td class="text-left">
            <input type="date" class="form-control form-control-sm" :min="minimumDate" :max="currentDate"  v-model="newVessel.date_keel_lay">
          </td>
        </tr>
        <tr>
          <th>Date of Launch: </th>
          <td class="text-left">
            <input type="date" class="form-control form-control-sm" :min="minimumDate" :max="currentDate" v-model="newVessel.date_launch">
          </td>
          <th>Date of Build: </th>
          <td class="text-left">
            <input type="date" class="form-control form-control-sm" :min="minimumDate" :max="currentDate"  v-model="newVessel.date_build">
          </td>
          <th>Age(Y): </th>
          <td class="text-left">
            <span >{{['',0].includes(newVessel.age) ? '' : newVessel.age}}</span> <!-- todo compute age -->
          </td>
        </tr>
        <tr>
          <th>ISM Management Company: </th>
          <td class="text-left">
            <select class="form-control form-control-sm"  v-model="newVessel.management_company_id">
              <option :value="company.id" :key="company.id" class="text-uppercase" v-for="company in mngmtCompanyKeys">{{company.name}}</option>
            </select>
          </td>
          <th >Management Branch Office: </th>
          <td class="text-left">
            <select class="form-control form-control-sm text-uppercase"  v-model="newVessel.management_office_id">
              <option value="1" v-for="office in mngmntOfficeKeys" :key="office.id">
                {{office.name }}
              </option>
            </select>
          </td>
          <th>Fleet: </th>
          <td class="text-left">
            <select class="form-control form-control-sm"  v-model="newVessel.fleet_id">
              <option :value="fleet.id" :key="fleet.id" class="text-uppercase" v-for="fleet in fleetKeys">{{fleet.name}}</option>
            </select>
          </td>
        </tr>
        <tr>
          <th>E-mail Address </th>
          <td class="text-left">
            <input type="email" class="form-control form-control-sm"  v-model="newVessel.email_address">

          </td>
          <th>Tel VSAT W/H direct phone: </th>
          <td class="text-left">
            <input type="tel" class="form-control form-control-sm"  v-model="newVessel.tel_vsat_direct">

          </td>
          <th>Tel Inmarsat FBB phone: </th>
          <td class="text-left">
            <input type="tel" class="form-control form-control-sm"  v-model="newVessel.tel_inmarsat_fbb">

          </td>
        </tr>
        <tr>
          <th>Tel VSAT Ship's office direct phone </th>
          <td class="text-left">
            <input type="tel" class="form-control form-control-sm"  v-model="newVessel.tel_vsat_ship_office">

          </td>
          <th>Fax Inmarsat FBB: </th>
          <td class="text-left">
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.fax_inmarsat_fbb">

          </td>
          <th>Inmarsat C: </th>
          <td class="text-left">
            <input type="tel" class="form-control form-control-sm"  v-model="newVessel.inmarsat_c">

          </td>
        </tr>
        <tr>
          <th>Mobile (In port only)</th>
          <td class="text-left">
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.mobile">

          </td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <th colspan="6">
            <hr />
          </th>
        </tr>
        <tr>
          <th>Registered Owner 1</th>
          <td>
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.registered_owner_one">

          </td>
          <td colspan="2"></td>
          <th>Registered Owner 2</th>
          <td>
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.registered_owner_two">

          </td>
        </tr>
        <tr>
          <th>BBC Owner 1</th>
          <td>
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.bbc_owner_one">

          </td>
          <td colspan="2"></td>
          <th>BBC Owner 2</th>
          <td>
            <input type="text" class="form-control form-control-sm"  v-model="newVessel.bbc_owner_two">

          </td>
        </tr>
      </table>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { AlertService } from '@/services/AlertService'
import { VesselService } from '@/services/VesselService'
import { DataService } from '@/services/DataService'
import DateMixin from "@/mixins/DateMixin";
export default {
  name: 'VesselAdd',
  mixins:[DateMixin],
  data () {
    return {
      newVessel: {
        age: ''
      }
    }
  },
  methods: {
    ...mapActions(['getMngmtOfficeKeys', 'getVesselDetail', 'getManagementCompanyKeys', 'getFleetListKeys', 'getCountryListKeys', 'getVesselTypeKeys']),
    createVessel: async function () {
      if (!await AlertService.confirmCreateAlert()) {
        return
      }
      const data = await VesselService.addVessel(this.newVessel)
      if (data) {
        AlertService.successAlert('Vessel Added Successfully', 'CREATE VESSEL')
        await this.$router.push({ name: 'VesselParticular', params: { id: data.id } }).catch(() => {})
      }
    },
    async cancelAddVessel(){
      if(await AlertService.cancelAlert()){
        this.$router.push({name:'VesselIndex'});
      }
    }
  },
  created () {
    this.getMngmtOfficeKeys()
    this.getManagementCompanyKeys()
    this.getFleetListKeys()
    this.getCountryListKeys()
    this.getVesselTypeKeys()
  },
  computed: {
    ...mapGetters(['mngmntOfficeKeys', 'vessel', 'fleetKeys', 'mngmtCompanyKeys', 'countryKeys', 'vesselTypeKeys'])
  },
  watch: {
    'newVessel.date_build' () {
      this.newVessel.age = DataService.computeAge(this.newVessel.date_build)
    }
  }
}
</script>

<style scoped lang="scss">
#vesselDetailsTbl {
  th {
    width: 13rem;
  }
  td {
    padding: .25rem;
    text-align: left;
  }
}
#veselOwnerTbl {
  th {
    width: 13rem;
  }
  td {
    padding: .25rem;
    text-align: left;
  }
}
#vesselDetailsTbl td:nth-child(even){
  padding-right: 1.5rem
}
#vesselDetailsTbl td:last-child{
  padding-right: 0
}
</style>
